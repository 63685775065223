// This dispatcher page should perform additional validations and loading before forwarding user further.
import { mapGetters } from 'vuex';
import { Validator } from 'vee-validate';

/**
 * The map of position values to css classes partial names.
 * @type {{}}
 */
const POS_MAP = {
  0: 'start',
  1: 'center',
  2: 'end',
};
export default {
  inject: ['systemService', 'companyInfoService', 'alertService', 'remotePackService', 'changeLanguage'],

  data() {
    return {
      company: null,
      /**
       * The status of the page. Since only one page is used for company info display, form and results, the status is used.
       * - init, page data loading, not defined state
       * - na, not available state, something is wrong, company not found
       * - form, the voucher validation form is displayed
       * - result, after valid voucher request is submitted, the result displays the success, failed or some other result of voucher.
       */
      status: 'init',

      /**
       * Message for public purchaser user, if any.
       */
      message: '',

      /**
       * The main form used to submit voucher code for activation.
       */
      frm: {
        userName: '',
        userNameR: '',
        voucherCode: '',
      },

      /**
       * Flag that all resources are loaded.
       */
      initDone: false,

      /**
       * The loading result for button progress indicator.
       */
      loadingResult: false,
    };
  },
  async created() {
    // scroll to top to try to avoid the display of search bar on mobile devs
    window.scrollTo(0, 0);
    // create custom validator here in order to have a reference to Vue instances
    // NOTE: create custom validation for custom translation of voucher code confirmation
    const userNameConfirmFn = (value) => {
      if (value !== this.frm.userName) {
        return {
          valid: false,
          data: {
            // NOTE: using the same translation as for REST API response
            message: this.$t('error.bad_request.voucher_activation.username_repeat_invalid'),
          },
        };
      }
      return {
        valid: true,
      };
    };

    Validator.extend('userNameConfirm', {
      validate: userNameConfirmFn,
      getMessage: (field, params, data) => data.message,
    });
    // init page
    await this.init();
  },
  computed: {
    ...mapGetters(['sysInfo', 'languages']),

    /**
     * Computed validation rule evaluated based on the state of voucher code field in form.
     * Do not validate the repeat password til password is validated.
     */
    // codeRepeatValidationRule() {
    //   if (this.fields.voucherCode && this.fields.voucherCode.validated && this.fields.voucherCode.valid) {
    //     return {
    //       // field is required and must match
    //       required: true,
    //       codeConfirm: 'voucherCode',
    //     };
    //   }

    /**
     * Computed validation rule evaluated based on the state of user name field in form.
     * Do not validate the field til userName is validated.
     */
    userNameRepeatValidationRule() {
      if (this.fields.userName && this.fields.userName.validated && this.fields.userName.valid) {
        return {
          // field is required and must match
          required: true,
          userNameConfirm: 'userName',
        };
      }

      // no validation if password is not valid yet
      return {};
    },

    formPositionCssClass() {
      let posClass = 'align-center justify-center';
      if (this.company) {
        const { afrmHPos, afrmVPos } = this.company;
        posClass = `justify-${POS_MAP[afrmHPos]} align-${POS_MAP[afrmVPos]}`;
      }
      return posClass;
    },

    /**
     * Shoo action bar depending on the status.
     * @returns {boolean}
     */
    showActions() {
      return this.status !== 'success';
    },

    /**
     * The reactive mask form, initialized when company loaded.
     * @returns {string}
     */
    vCodeInputMask() {
      return this.company?.vaFrmConfig.pattern || '';
    },
  },
  watch: {},
  methods: {
    /**
     * Initializes the page based on working mode.
     */
    async init() {
      // validate the company...
      const companySlug = this.$router.currentRoute.params.slugName;
      if (companySlug) {
        try {
          const company = await this.companyInfoService().retrieveCompanyPublicInfo(companySlug);
          this.company = company;
        } catch (e) {
          // console.log(e);
          this.status = 'na';
          // the message can be error or message to user
          this.message = e.message;
        }
      }
      this.initDone = true;
    },
    /**
     * Submits the form to activate the code.
     * @returns {Promise<void>}
     */
    async submitCode() {
      // reset previous statuses and messages on every new action
      this.status = 'form';
      this.message = '';
      const vldResult = await this.$validator.validateAll();

      if (vldResult) {
        let recapToken;

        // trigger captcha only in configured in client app on build
        if (this.$recaptchaLoaded) {
          await this.$recaptchaLoaded();
          // Execute reCAPTCHA with action "login".
          recapToken = await this.$recaptcha('voucherActivate');
        }
        const apiDTO = { ...this.trimWhitespace(this.frm), slugName: this.company.slugName, recapToken };
        try {
          this.loadingResult = true;
          const result = await this.remotePackService().activatePack(apiDTO);

          this.message = result;
          this.status = 'success';
        } catch (e) {
          this.message = e.message;
        } finally {
          this.loadingResult = false;
        }
      }
    },
  },
};
