import axios from 'axios';
import qs from 'query-string';
import dayjs from 'dayjs';

/**
 * The client service to support company management
 */
export default class CompanyManageService {
  constructor({ store, router, i18n }) {
    this.store = store;
    this.router = router;
    this.i18n = i18n;
    // this.init();
  }

  /**
   * Retrieves company by specified serial id.
   * @param {number} id - The serial id.
   * @param {object} options - The options for reading company data.
   * @param {{ sflt: string}} options.filter - The filter for reading company data.
   * sflt - the enumerated scope filter
   * @returns {Promise<object>} - The promise with object of retrieved company.
   */
  async retrieveCompany(id, options = {}) {
    let queryString = qs.stringify(options.filter, { arrayFormat: 'bracket' });
    if (queryString) {
      queryString = `?${queryString}`;
    }
    const res = await axios.get(`api/companies/${id}${queryString}`);
    return res.data;
  }

  /**
   * Load companies users (optionally with some query).
   * @param {object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {Array<string>} options.filter.sb - The array of 'sort by' fields used for sorting.
   * @param {string} options.filter.so - The array of 'sort order' fields associated with each specified 'sort by' field.
   * @param {string} options.filter.sflt - The value of 'scope filter'.
   */
  async retrieveCompanies(
    options = {
      filter: {
        sb: [],
        so: [],
        sflt: undefined,
      },
    },
  ) {
    const res = await axios.get(`api/companies?${qs.stringify(options.filter, { arrayFormat: 'bracket' })}`);
    return res.data;
  }

  async createCompany(
    formDTO,
    options = {
      filter: {
        sflt: undefined,
      },
    },
  ) {
    const res = await axios.post(`api/companies?${qs.stringify(options.filter, { arrayFormat: 'bracket' })}`, formDTO);
    return res.data;
  }

  /**
   * Invokes REST API to company with data specified in create/edit form.
   * @param {object} formDTO - The form DTO.
   * @param {object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {string} options.filter.sflt - The scope filter used to for applying scope filter on repository data.
   * @returns {Promise<object>} - The promised updated Employee data.
   */
  async updateCompany(
    formDTO,
    options = {
      filter: {
        sflt: undefined,
      },
    },
  ) {
    // form is already nice and clean, no need to strip data
    // inject company id into the dto
    const dto = {
      ...formDTO,
    };

    const res = await axios.put(`api/companies?${qs.stringify(options.filter, { arrayFormat: 'bracket' })}`, dto);
    return res.data;
  }

  /**
   * The managed company id is evaluated based on authority of logged user.
   * The employee with company admin authority will have company id initialized from the model, while
   * system admin can have this initialized form the route.
   */
  evalCompanyId() {
    return this.router.currentRoute.params.id ? this.router.currentRoute.params.id : this.store.getters.getCompanyId;
  }

  async removeCompany({ id } = {}) {
    const res = await axios.delete(`api/companies/${id}`);
    return res.data;
  }

  // ====================
  // Company Campaigns management
  /**
   * Load company campaigns.
   * @param {object} options - The options for retrieving the campaigns.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {Array<string>} options.filter.sb - The array of 'sort by' fields used for sorting.
   * @param {string} options.filter.so - The array of 'sort order' fields associated with each specified 'sort by' field.
   * @param {string} options.filter.sflt - The value of 'scope filter'.
   */
  async retrieveCompanyCampaigns(
    options = {
      filter: {
        sb: [],
        so: [],
        sflt: undefined,
      },
    },
  ) {
    const res = await axios.get(
      `api/companies/${this.router.currentRoute.params.companyId}/campaigns?${qs.stringify(options.filter, {
        arrayFormat: 'bracket',
      })}`,
    );
    return res.data;
  }

  /**
   * Creates the company campaign with set of voucher codes.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The DTO of created document.
   */
  async createCompanyCampaign(formDTO) {
    const apiDTO = {
      ...formDTO,
      // append company id
      companyId: this.router.currentRoute.params.companyId,
    };

    const res = await axios.post(`api/campaign-gen`, apiDTO, {});
    return res.data;
  }

  /**
   * NOTE: We need companyId in route in order to invoke follow the API.
   * @param {object} options - The delete params.
   * @param {number} options.companyId - The serial id of company.
   * @param {number} options.campaignId - Campaign id.
   * @returns {Promise<object>} - The result of delete.
   */
  async removeCompanyCampaign({ companyId, campaignId }) {
    const res = await axios.delete(`api/companies/${companyId}/campaigns/${campaignId}`);
    return res.data;
  }

  /**
   * Downloads company report, default report type: orphan vouchers CSV.
   * @param {object} company - The company DTO.
   * @param {object} options - The options for company report dl.
   * @returns {Promise<object>} - The DTO of created document.
   */
  async dlCompanyReport(company, options = { format: 'csv' }) {
    const opt = { format: 'csv', ...options };
    const { format } = opt;
    const res = await axios.get(`api/companies/${company.id}/report?${qs.stringify(opt, { arrayFormat: 'bracket' })}`, {
      responseType: 'arraybuffer',
      headers: {
        // So if a request has no payload, you don't have to send a Content-Type request header,
        // and the same goes for your response: no body — no header necessary.
        // Some servers may require you to provide a Content-Type in a request even if the request has no payload;
        // the server should return a 415 Unsupported Media Type response if you omit it.
        // 'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
    });

    // NOTE: The filename would not be used from headers
    const filename = `${dayjs().format('YYYY-MM-DD HHmmss')} - ${this.i18n.t(
      format === 'csv' ? 'r.orphan_vouchers.file_name' : 'r.orphan_vouchers_log.file_name',
      this.store.getters.currentLanguage,
      {
        companyName: company.name,
      },
    )}.${opt.format}`;

    // now custom download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  /**
   * Downloads campaign vouchers as CSV.
   * @param {object} campaign - The campaign DTO.
   * @returns {Promise<object>} - The DTO of created document.
   */
  async dlCampaignVouchers(campaign) {
    const res = await axios.get(`api/campaign-dl/${campaign.id}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
    });

    const hdrs = res.headers;
    const filename = hdrs['content-disposition'].match(/filename="(.+)"/)[1];
    // console.log(hdrs);

    // now custom download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  /**
   * Downloads campaign report, default report type: activated vouchers CSV.
   * @param {object} campaign - The campaign DTO.
   * @param {object} company - The company DTO.
   * @param {object} options - The options for campaign report dl.
   * @returns {Promise<object>} - The DTO of created document.
   */
  async dlCampaignReport(campaign, company, options = { format: 'csv' }) {
    const opt = { format: 'csv', ...options };
    const res = await axios.get(`api/campaign-report/${campaign.id}?${qs.stringify(opt, { arrayFormat: 'bracket' })}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
    });

    // NOTE: The filename would not be used from headers
    // const hdrs = res.headers;
    // const filename = hdrs['content-disposition'].match(/filename="(.+)"/)[1];
    // Instead, it would be built out of involved data and a label
    const filename = `${dayjs().format('YYYY-MM-DD HHmmss')} - ${this.i18n.t(
      opt.format === 'csv' ? 'r.campaign_activated_vouchers.file_name' : 'r.campaign_vouchers_log.file_name',
      this.store.getters.currentLanguage,
      {
        companyName: company.name,
        campaignName: campaign.name,
        packName: `${campaign.pack.name} (${campaign.pack.packType})`,
      },
    )}.${opt.format}`;

    // use export file name prepared with campaign list
    // const filename = `${dayjs().format('YYYY-MM-DD HHmmss')} - ${campaign.activatedRptName}`;

    // console.log(hdrs);

    // now custom download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  // ====================
  // Company CI management

  /**
   * Initiates download the company's CI resource file.
   * @param {string} ciResType - The name of the CI field to get, e.g. BACKGROUND.
   * @returns {Promise<object>} The promised doc file data.
   */
  async dlCompanyCIResourceFile(ciResType) {
    const res = await axios.get(`api/companies/${this.router.currentRoute.params.companyId}/ci/${ciResType}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
    });

    const hdrs = res.headers;
    const filename = hdrs['content-disposition'].match(/filename="(.+)"/)[1];

    // now custom download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  /**
   * Creates the CI resource.
   * At the same time, this method will force the source of the resource to LOCAL.
   *
   * NOTE: for create, when we have an upload - the multi part request is used.
   * @param {Blob} ciResFile - The CI file blob (File API?).
   * @param {object} options - The options for upload.
   * @param {object} options.ciResType - The name of CI resource to upload.
   * @returns {Promise<object>} - The DTO of created CI resource.
   */
  async createCompanyCIResource(ciResFile, options) {
    const formData = new FormData();

    formData.append('ciResType', options.ciResType);
    formData.append('ciResFile', ciResFile);

    const res = await axios.post(`api/companies/${this.router.currentRoute.params.companyId}/ci`, formData, {
      /*
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
*/
    });
    return res.data;
  }

  async removeCompanyCIResource(ciResource) {
    const res = await axios.delete(`api/companies/${this.router.currentRoute.params.companyId}/ci/${ciResource}`);
    return res.data;
  }

  /**
   * Configures CI resources.
   *
   * NOTE: This is used to save the configuration of CI, not for file upload. Right now these two are separated.
   * @param {object} dto - The DTO prepared from form.
   * @param {object} options - The options for config save.
   * @param {string} options.ciResType - The name of the CI field to get, e.g. BACKGROUND.
   * @returns {Promise<object>} - The DTO of updated config.
   */
  async saveCompanyCiConfig(dto, options) {
    const apiDTO = {
      id: this.router.currentRoute.params.companyId,
      ciResType: options.ciResType,
      ...dto,
    };

    const res = await axios.post(`api/companies/${this.router.currentRoute.params.companyId}/ci-config`, apiDTO, {});
    return res.data;
  }
}
